<template>
  <div>
    <div class="card-type" v-for="(item,index) in list" :key="index">
      <img :src="item.img_url" :onerror="errorImage" alt="">
      <div class="card-type-title">{{item.title}}</div>
      
    </div>
  </div>

</template>
<script>
  export default {
    name:"cardType",
    props:{
      list:Array
    },
    data(){
      return {
        errorImage: 'this.src="' + require('@/assets/images/error_img.jpg') + '"'  ,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .card-type{
    width: 160px;
    padding-bottom: 10px;
    box-sizing: border-box;
    img{
      display: block;
      width:100px;
      height: auto;
      margin: 10px auto;
    }
    &-title{
      word-break: break-all;
    }
  }
</style>
